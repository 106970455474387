import { Badge } from "antd";
import useCart from "grandus-lib/hooks/useCart";
import { get } from "lodash";

import Link from "components/_others/link/Link";

import { CartIcon } from "components/_others/icons";

const CartButton = () => {
  const { cart, isLoading } = useCart();
  return (
    <Link href="/kosik" as="/kosik">
      <a>
        <div className={"icon"}>
          <Badge
            count={get(cart, "pieceCount", 0)}
            style={{
              backgroundColor: "rgb(175, 202, 5)",
              color: "rgb(80, 35, 122)",
              fontWeight: "bolder",
            }}
          >
            <div
              style={{
                width: "32px",
                height: "32px",
                display: "inline-block",
              }}
            >
              <CartIcon />
            </div>
          </Badge>
        </div>
      </a>
    </Link>
  );
};

export default CartButton;
