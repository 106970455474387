// import UserButton from "components/user/AuthButton";
import { useTranslation } from "next-i18next";
import useSWR from "swr";
import { get, find } from "lodash";
import CartButton from "components/cart/CartButton";
import useUser from "grandus-lib/hooks/useUser";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import Logo from "./Logo";
import upperFirst from "lodash/upperFirst";

import Link from "components/_others/link/Link";
import {
  CloseOutlined,
  MenuOutlined,
  CheckOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import { LocationIcon, SearchIcon, UserIcon } from "components/_others/icons";

import Menu from "components/menu/Menu";
import InfolineStatus from "components/header/infolineStatus";
import Transition from "pages/_transition";

const HeaderUser = ({}) => {
  const { t } = useTranslation();
  const { user, isLoading: isLoadingUser } = useUser();
  return (
    <Link href="/prihlasenie" as={`/prihlasenie`}>
      <a>
        <div className={"icon"}>
          <div
            style={{
              width: "32px",
              height: "32px",
              display: "inline-block",
            }}
          >
            <UserIcon />
          </div>
          {isLoadingUser || user ? (
            <div className={"badge"}>
              {isLoadingUser ? <LoadingOutlined /> : <CheckOutlined />}
            </div>
          ) : (
            ""
          )}
        </div>
      </a>
    </Link>
  );
};

const HeaderSearchIcon = ({ onClickToggleOpenSearch, openedSearch }) => {
  const router = useRouter();
  const isHomepage = router?.route == "/";
  const inlineStyle = { fontSize: "2em" };
  return (
    <li className={`show-search ${isHomepage ? "homepage" : ""}`}>
      <a href="#" onClick={onClickToggleOpenSearch}>
        <div className={"icon"}>
          {!openedSearch ? (
            <SearchIcon />
          ) : (
            <CloseOutlined style={inlineStyle} />
          )}
        </div>
      </a>
    </li>
  );
};

const Header = () => {
  const { t } = useTranslation();
  const { data } = useSWR(
    "/api/pages/header",
    (url) => fetch(url).then((r) => r.json()),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  // const webinstance = get(data, "webinstance", {});
  const staticContent = find(get(data, "staticBlocks", []), [
    "hash",
    "header_block_doprava",
  ]);

  const searchInputRef = useRef();
  const [openedSearch, setOpenedSearch] = useState(false);
  const onClickToggleOpenSearch = () => {
    if (openedSearch) {
      setOpenedSearch(false);
    } else {
      setOpenedSearch(true);
    }
  };

  useEffect(() => {
    if (openedSearch) {
      searchInputRef.current.focus();
    }
  }, [openedSearch]);

  const [openedMenu, setOpenedMenu] = useState(false);
  const onClickToggleOpenMenu = () => {
    if (openedMenu) {
      setOpenedMenu(false);
      document.body.style.overflow = "";
    } else {
      setOpenedMenu(true);
      document.body.style.overflow = "hidden";
    }
  };
  const updateOpenedMenu = (opened) => {
    if (openedMenu !== opened) {
      setOpenedMenu(opened);
    }
  };

  return (
    <>
      <header id={"header"}>
        <div className={"container"}>
          <div className={"logo"}>
            <Logo />
            <div
              className={"message"}
              dangerouslySetInnerHTML={{
                __html: get(staticContent, "content", ""),
              }}
            />
          </div>
          <div className={"menu"}>
            <div className="icon-menu-wrapper">
              <ul>
                <HeaderSearchIcon
                  onClickToggleOpenSearch={onClickToggleOpenSearch}
                  openedSearch={openedSearch}
                />
                <li className={"show-stores"}>
                  <Link
                    href={{
                      pathname: "/stranka/[id]",
                      query: { id: "contacte" },
                    }}
                  >
                    <a>
                      <span className={"text"}>
                        {upperFirst(t("common:contact"))}
                      </span>
                      <div className={"icon"}>
                        <div
                          style={{
                            width: "32px",
                            height: "32px",
                            display: "inline-block",

                            color: "white",
                          }}
                        >
                          <LocationIcon />
                        </div>
                      </div>
                    </a>
                  </Link>
                </li>
                <li>
                  <HeaderUser />
                </li>
                <li className={"show-infoline"}>
                  <InfolineStatus type="small" revalidateOnFocus={false} />
                </li>
                <li>
                  <CartButton />
                </li>
                <li className={"show-menu"}>
                  <a href="#" onClick={onClickToggleOpenMenu}>
                    <div className={"icon"}>
                      {!openedMenu ? (
                        <MenuOutlined style={{ fontSize: "1.6em" }} />
                      ) : (
                        <CloseOutlined style={{ fontSize: "1.6em" }} />
                      )}
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className={`${"search"} ${openedSearch ? "open" : ""}`}>
            <div className={"search-wrapper"}>
              <input
                type="text"
                placeholder={upperFirst(t("common:search"))}
                data-id="search-input"
                ref={searchInputRef}
              />
              <button type="submit" data-id="search-button">
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    display: "inline-block",
                  }}
                >
                  <SearchIcon width={20} height={20} />
                </div>
              </button>
            </div>
            <div className={"info"}>
              <InfolineStatus />
            </div>
          </div>
        </div>
      </header>
      <Transition>
        <Menu isOpen={openedMenu} updateOpenedMenu={updateOpenedMenu} />
      </Transition>
    </>
  );
};

export default Header;
