import { Badge } from "antd";

import { PhoneIcon } from "components/_others/icons";

import styles from "./InfolineStatus.module.scss";

const InfolineDefault = ({ available = false, phone = null, email = null }) => {
  return (
    <div className={styles?.infolineStatus}>
      <PhoneIcon />
      <Badge
        count={available ? "online" : "offline"}
        offset={[5, -5]}
        size="small"
        className={available ? styles.online : styles.offline}
      >
        {available ? (
          <a href={`tel:${phone.replace(/\s/g, "")}`}>{phone}</a>
        ) : (
          phone
        )}{" "}
        <br />
        <a href={`mailto:${email}`}>{email}</a>
      </Badge>
    </div>
  );
};
export default InfolineDefault;
