import Link from "next/link";
import Image from "next/image";

// import { useEffect, useState } from "react";

// const useTimer = () => {
//   const DEFAULT_TIMER = 14;
//   const [count, setCount] = useState(DEFAULT_TIMER);

//   useEffect(() => {
//     if (count > 0) {
//       const secondsLeft = setInterval(() => {
//         setCount(count - 1);
//       }, 1000);
//       return () => clearInterval(secondsLeft);
//     } else {
//       setCount(DEFAULT_TIMER);
//     }
//   });

//   return count;
// };

const Logo = ({}) => {
  // let timer = useTimer();

  return (
    <Link href="/" as={`/`}>
      <a className={"logo-anchor"}>
        {/* {timer > 10 ? ( */}
        <Image
          src={"/foon_logo_white_small.gif"}
          width={180}
          height={35}
          alt={"Foon logo"}
        />
        {/* ) : (
          <Image
            src={"/foon_logo_white.png"}
            width={180}
            height={35}
            alt={"Foon logo"}
          />
        )} */}
      </a>
    </Link>
  );
};

export default Logo;
