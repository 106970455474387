// import "scripts/wdyr";
import "../styles/custom-antd.css";
import "../styles/mobilonline-header.scss";
import "../styles/global.scss";
import "react-image-lightbox/style.css";
import "../styles/bootstrap-grid.min.css";
import "../styles/custom-grid.scss";

import Script from "next/script";

import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { appWithTranslation } from "next-i18next";

import Head from "next/head";
import MetaHead from "grandus-lib/components-atomic/MetaHead";

import { useEffect } from "react";

import * as Sentry from "@sentry/node";
import { RewriteFrames } from "@sentry/integrations";
import getConfig from "next/config";

import TagManager from "grandus-lib/utils/gtag";
import FBPixel from "grandus-lib/utils/fbpixel";

// import InfoBox from "components/InfoBox";
import Header from "components/header/Header";
import HeaderCart from "components/header/HeaderCart";
import Footer from "components/footer/Footer";
import FooterCart from "components/footer/FooterCart";

import { startsWith } from "lodash";
import CookieConsent from "components/_others/cookieConsent";
// import ExpressDelivery from "components/expressDelivery/ExpressDelivery";
// import Heureka from "components/_others/heureka2020";
// const PersonalConsultant = dynamic(
//   () => import("components/personalConsultant/PersonalConsultant"),
//   { ssr: false }
// );

import Router, { useRouter } from "next/router";
// import NProgress from "nprogress";

// const routeChangeCompleted = () => {
//   NProgress.done();
//   document.body.style.overflow = "";
// };

// Router.events.on("routeChangeStart", () => NProgress.start());
// Router.events.on("routeChangeComplete", routeChangeCompleted);
// Router.events.on("routeChangeError", routeChangeCompleted);

export function reportWebVitals(metric) {
  if (metric.label === "custom") {
    console.info(`⏱️ | ${metric.name} - ${metric.value}ms`);
  }
}

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, "app:///_next");
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

const LayoutHeader = ({}) => {
  const router = useRouter();
  const pathname = router?.pathname;
  if (startsWith(pathname, "/kosik") || startsWith(pathname, "/dashboard")) {
    return <HeaderCart />;
  }

  return <Header />;
};

const LayoutFooter = ({}) => {
  const router = useRouter();
  const pathname = router?.pathname;
  if (startsWith(pathname, "/kosik") || startsWith(pathname, "/dashboard")) {
    return <FooterCart />;
  }

  return <Footer />;
};

const CookieConsentLayout = ({}) => {
  const router = useRouter();
  const pathname = router?.pathname;
  if (startsWith(pathname, "/dashboard")) {
    return null;
  }

  return <CookieConsent />;
};

const LayoutHeadData = () => {
  useEffect(() => {
    TagManager.registerPageViewTracking(Router);
    FBPixel.registerPageViewTracking(Router);
  }, []);
  return (
    <Head>
      <meta
        name="google-site-verification"
        content="ulsbsJVnlRukwidV5NEkFD-M5LmNNGwMpVeFQbPSJ-Q"
      />
      <meta 
        name="facebook-domain-verification" 
        content="by849y1upolccfeto6q0w4uvbfbozi" 
      />
      <MetaHead>
        <link rel="manifest" href="/manifest.json" />
        <meta name="apple-mobile-web-app-title" content="foon.ro" />
        <meta name="application-name" content="foon.ro" />
        <link rel="apple-touch-icon" sizes="180x180" href="https://www.foon.ro/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="https://www.foon.ro/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="https://www.foon.ro/favicon-16x16.png" />
        <link rel="manifest" href="https://www.foon.ro/site.webmanifest" />
        <link rel="mask-icon" href="https://www.foon.ro/safari-pinned-tab.svg" color="#50237a" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
      </MetaHead>
    </Head>
  );
};

const GrandusApp = ({ Component, pageProps }) => {
  return (
    <>
      <Script src={"https://scripts.luigisbox.com/LBX-261232.js"} />
      <Script
        src={"https://www.googletagmanager.com/gtag/js?id=UA-15733214-10"}
      />
      <Script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-NCNFL5R');`}
      </Script>
      <Script strategy={"lazyOnload"}>
        {`!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window,document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '2832745847011886');
          fbq('track', 'PageView');`}
      </Script>
      <LayoutHeadData />
      <div className="headerSection">
        {/* {useMemo(
          () => (
            <InfoBox />
          ),
          []
        )} */}
        <LayoutHeader />
      </div>

      <Component {...pageProps} />

      <LayoutFooter />
      {/* <ExpressDelivery /> */}
      {/* <Heureka /> */}
      {/* <PersonalConsultant /> */}
      {/*<CookieConsentLayout />*/}
    </>
  );
};

export default appWithTranslation(GrandusApp);

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common", "home"])),
      // Will be passed to the page component as props
    },
  };
}
