import get from "lodash/get";

const routes = {
  "/blog": {
    sk: "/blog",
  },
  "/blog/[id]": {
    sk: "/blog/[id]",
  },
  "/blog/kategoria/[id]/[[...category]]": {
    sk: "/blog/kategoria/[id]/[[...category]]",
    ro: "/blog/categoria/[id]/[[...category]]",
  },
  "/kategoria/[category]/[[...parameters]]": {
    sk: "/kategoria/[category]/[[...parameters]]",
    ro: "/categoria/[category]/[[...parameters]]",
  },
  "/kategoria/[category]/~filter/[...filter]": {
    sk: "/kategoria/[category]/~filter/[...filter]",
    ro: "/categoria/[category]/~filter/[...filter]",
  },
  "/kategoria/[category]/filter/[...filter]": {
    sk: "/kategoria/[category]/filter/[...filter]",
    ro: "/categoria/[category]/filter/[...filter]",
  },
  "/kosik": {
    sk: "/kosik",
    hu: "/kosar",
    ro: "/cos",
  },
  "/kosik/doprava-a-platba": {
    sk: "/kosik/doprava-a-platba",
    hu: "/kosar/szallitas-es-fizetes",
    ro: "/cos/cumparaturi-si-plata",
  },
  "/kosik/kontakt": {
    sk: "/kosik/kontakt",
    hu: "/kosar/kapcsolat",
    ro: "/cos/contact",
  },
  "/objednavka/dakujeme": {
    sk: "/objednavka/dakujeme",
    hu: "/rendelos/koszonjuk",
    ro: "/comanda/multumim",
  },
  "/odhlasenie": {
    sk: "/odhlasenie",
    hu: "/kijelentkezes",
    ro: "/delogare",
  },
  "/porovnanie": {
    sk: "/porovnanie",
    hu: "/osszehasonlitas",
    ro: "/comparare",
  },
  "/predajna/[id]": {
    sk: "/predajna/[id]",
    hu: "/uzlet/[id]",
    ro: "/magazin/[id]",
  },
  "/predajne": {
    sk: "/predajne",
    hu: "/uzletek",
    ro: "/magazine",
  },
  "/prihlasenie": {
    sk: "/prihlasenie",
    hu: "/bejelentkezes",
    ro: "/autentificare",
  },
  "/produkt/[id]": {
    sk: "/produkt/[id]",
    hu: "/termek/[id]",
    ro: "/produs/[id]",
  },
  "/registracia": {
    sk: "/registracia",
    hu: "/regisztracio",
    ro: "/inregistrare",
  },
  "/stranka/[id]": {
    sk: "/stranka/[id]",
    hu: "/oldal/[id]",
    ro: "/pagina/[id]",
  },
  "/ucet/dodacie-listy": {
    sk: "/ucet/dodacie-listy",
    hu: "/fiok/szallito-levelek",
    ro: "/cont/note-de-livrare",
  },
  "/ucet/faktury": {
    sk: "/ucet/faktury",
    hu: "/fiok/szamlak",
    ro: "/cont/facturi",
  },
  "/ucet/kredity": {
    sk: "/ucet/kredity",
    hu: "/fiok/kreditek",
    ro: "/cont/credite",
  },
  "/ucet/kupony": {
    sk: "/ucet/kupony",
    hu: "/fiok/kuponok",
    ro: "/cont/cupoane",
  },
  "/ucet/objednavky": {
    sk: "/ucet/objednavky",
    hu: "/fiok/rendelesek",
    ro: "/cont/comenzi",
  },
  "/ucet/objednavky/[id]": {
    sk: "/ucet/objednavky/[id]",
    hu: "/fiok/rendelesek/[id]",
    ro: "/cont/comenzile/[id]",
  },
  "/ucet/profil": {
    sk: "/ucet/profil",
    hu: "/fiok/profil",
    ro: "/cont/profil",
  },
  "/ucet/unauthorized": {
    sk: "/ucet/unauthorized",
    hu: "/fiok/nem-engedelyezett",
    ro: "/cont/unauthorized",
  },
  "/vyhladavanie/[term]/[[...parameters]]": {
    sk: "/vyhladavanie/[term]/[[...parameters]]",
    hu: "/kereses/[term]/[[...parameters]]",
    ro: "/cautare/[term]/[[...parameters]]",
  },
  "/wishlist": { sk: "/wishlist", hu: "/kedvencek", ro: "/wishlist" },
  "/zabudnute-heslo": {
    sk: "/zabudnute-heslo",
    hu: "/elfelejtett-jelszo",
    ro: "/parola-uitata",
  },
  "/zabudnute-heslo/[hash]": {
    sk: "/zabudnute-heslo/[hash]",
    hu: "/elfelejtett-jelszo/[hash]",
    ro: "/parola-uitata/[hash]",
  },
};

export const getPathname = (pathname, locale = false) => {
  return locale ? get(routes, [pathname, locale], pathname) : pathname;
};

export const getUrlObject = (pathname, query = {}, locale = "") => {
  return {
    pathname: getPathname(pathname, locale),
    query,
  };
};
