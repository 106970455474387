const PhoneIcon = ({ width = 32, height = 32 }) => (
  <svg height={width} width={height} viewBox="0 0 500 500">
    <g fill="currentColor">
      <path
        d="M482.8,369l-70.5-70.6c-10.2-10.2-23.9-15.8-38.5-15.8c-15.2,0-29.5,6-40.3,16.8l-25.3,25.3c-18.2-10.1-46.8-26.6-75.6-55.5
	c-27-27-42.9-53-55.5-75.6l25.3-25.3c22-22,22.4-57.4,0.9-78.9L132.7,19C122.5,8.7,108.8,3.1,94.2,3.1c-14.7,0-28.8,5.7-39.5,16
	c-6.5,5.3-37.5,33-49,89.2c-16.2,78.9,20.6,154.5,126.9,260.8c118.6,118.6,220.3,127.8,248.6,127.8c6,0,9.7-0.4,10.7-0.5
	c56.4-6.6,76.6-31.7,91.4-50C503.1,421.8,502.9,389.2,482.8,369z M456.5,424.9L456.5,424.9l-3.1-2.5c-13.5,16.7-25.2,31.2-66,36
	c-0.1,0-2.3,0.2-6.3,0.2c-24.6,0-113.3-8.4-221.5-116.6C63.7,246.1,29.9,180.6,43.1,116c9.6-46.8,34.6-66.4,35.6-67.1l0.9-0.7
	l1.2-1.2c3.6-3.6,8.3-5.6,13.3-5.6c4.4,0,8.5,1.6,11.5,4.6l70.5,70.5c6.6,6.5,6.1,17.6-1,24.7l-31.1,31.1l-0.6,0.6
	c-9.4,10.4-8.4,24.3-3.2,33.5c13,23.4,30.8,55.5,65.1,89.9c34.2,34.2,66.2,52,89.6,64.9c2.7,1.5,8.2,4,15.1,4
	c8.2,0,15.7-3.4,21.3-9.7l29.1-29.1c3.6-3.6,8.3-5.6,13.2-5.6c4.4,0,8.5,1.6,11.5,4.6l70.5,70.5c7.2,7.2,3.9,18.7-2.2,26.3
	L456.5,424.9L456.5,424.9L456.5,424.9z"
      />
    </g>
  </svg>
);

export default PhoneIcon;
