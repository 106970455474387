import { Fragment } from "react";
import { PageTransition } from "next-page-transitions";
import { useRouter } from "next/router";
const TIMEOUT = 1;

const Transition = ({ children, customString = "" }) => {
  const router = useRouter();
  return (
    <>
      <PageTransition
        timeout={TIMEOUT}
        classNames="page-transition"
        skipInitialTransition={true}
        loadingClassNames="loading-indicator"
      >
        <Fragment key={`transition-${customString}-${router.asPath}`}>
          {children}
        </Fragment>
      </PageTransition>
      <style jsx global>{`
        /* LOADER */

        #nprogress {
          pointer-events: none;
        }

        #nprogress .bar {
          background: #afca05;
          box-shadow: 0 0 18px #afca05, 0 0 9px #afca05;

          position: fixed;
          z-index: 1031;
          top: 0;
          left: 0;

          width: 100%;
          height: 2px;
        }

        .nprogress-custom-parent {
          overflow: hidden;
          position: relative;
        }

        .nprogress-custom-parent #nprogress .spinner,
        .nprogress-custom-parent #nprogress .bar {
          position: absolute;
        }

        @-webkit-keyframes nprogress-spinner {
          0% {
            -webkit-transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
          }
        }
        @keyframes nprogress-spinner {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        /* TRANSITION */
        .page-transition-enter {
          opacity: 0;
          transform: translate3d(0, 20px, 0);
        }
        .page-transition-enter-active {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition: opacity ${TIMEOUT}ms, transform ${TIMEOUT}ms;
        }
        .page-transition-exit {
          opacity: 1;
        }
        .page-transition-exit-active {
          opacity: 0;
          transition: opacity ${TIMEOUT}ms;
        }
        .loading-indicator-appear,
        .loading-indicator-enter {
          opacity: 0;
        }
        .loading-indicator-appear-active,
        .loading-indicator-enter-active {
          opacity: 1;
          transition: opacity ${TIMEOUT}ms;
        }
      `}</style>
    </>
  );
};

export default Transition;
