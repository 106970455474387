import { PhoneIcon } from "components/_others/icons";
import upperFirst from "lodash/upperFirst";
import { useTranslation } from 'next-i18next';

import styles from "./InfolineStatus.module.scss";

const Content = ({ available = false }) => {
  const { t } = useTranslation();
  return (
    <div>
      <span>{upperFirst(t("common:infoline"))}</span>
      <span>{available ? "ONLINE" : "OFFLINE"}</span>
    </div>
  );
};

const InfolineSmall = ({ available = false, phone = null }) => (
  <div
    className={`${styles?.infolineStatus} ${styles?.infolineStatusSmall} ${
      available ? styles.online : styles.offline
    }`}
  >
    {available ? (
      <>
        <a href={`tel:${phone.replace(/\s/g, "")}`}>
          <PhoneIcon width={20} height={20} />
          <Content available={available} />
        </a>
      </>
    ) : (
      <div className={styles.fakeLink}>
        <PhoneIcon width={20} height={20} />
        <Content available={available} />
      </div>
    )}
  </div>
);

export default InfolineSmall;
