const UserIcon = ({ width = 32, height = 32 }) => (
  <svg
    height={width}
    width={height}
    viewBox={`0 0 32 32`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>users</title>
    <g>
      <path d="M18.552 4.413a7.47 7.47 0 0 0-3.74-.421c-.168.025-.336.042-.496.075a7.356 7.356 0 0 0-3.908 2.08C9.39 7.2 8.775 8.447 8.598 9.861a6.068 6.068 0 0 0 .758 3.823c.766 1.356 1.903 2.375 3.393 3.007 1.44.623 2.956.783 4.523.48 1.743-.337 3.174-1.188 4.286-2.51a6.231 6.231 0 0 0 1.44-3.419 6.19 6.19 0 0 0-.817-3.78c-.808-1.399-2.038-2.426-3.63-3.05zm1.322 9.086c-.783.968-1.82 1.566-3.09 1.81a5.406 5.406 0 0 1-.708.068c-.084.008-.169.008-.244.025h-.034c-1.726-.042-3.124-.707-4.143-1.987-.767-.96-1.087-2.063-.96-3.284.126-1.163.657-2.156 1.566-2.973 1.036-.901 2.282-1.347 3.705-1.314 1.306.042 2.45.497 3.394 1.348.901.808 1.432 1.827 1.533 3.031.11 1.213-.236 2.308-1.02 3.276zm4.833 13.92c.304-.244.413-.539.312-.918-.025-.084-.05-.168-.084-.253a8.665 8.665 0 0 0-2.821-4.1c-1.802-1.474-3.941-2.232-6.367-2.257-.53 0-.968.033-1.398.084-1.338.202-2.568.623-3.663 1.28-1.558.918-2.745 2.147-3.562 3.671a6.31 6.31 0 0 0-.547 1.34.808.808 0 0 0 .194.867c.26.278.64.395 1.018.32.363-.076.64-.32.758-.657l.06-.152a6.507 6.507 0 0 1 1.49-2.476c1.347-1.414 3.057-2.223 5.052-2.391.236-.025.455-.025.674-.034a7.46 7.46 0 0 1 3.25.733c1.651.766 2.864 1.97 3.596 3.537.135.294.253.598.354.934.05.186.16.337.295.455.42.345.96.362 1.39.017z" />
    </g>
  </svg>
);

export default UserIcon;
