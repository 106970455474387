import useSWR from "swr";
import Content from "grandus-lib/components-atomic/content/Content";
import { get, map } from "lodash";

import styles from "./Footer.module.scss";
import { useTranslation } from "next-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const { data } = useSWR(
    "/api/pages/footer?isCart=1",
    (url) => fetch(url).then((r) => r.json()),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const content = get(data, "content", []);
  const year = new Date().getFullYear();

  return (
    <>
      <footer id={styles.footer} className={`footerCart`}>
        <div className={"container"}>
          {map(content, (page, index) => {
            const { id, content, customJavascript, customCss } = page;
            return (
              <Content
                content={content}
                css={customCss}
                js={customJavascript}
                key={`footer-page-${id}-${index}`}
              />
            );
          })}
        </div>
        <div className={styles.row}>
          <div className={styles.col + " " + styles.copyright}>
            {year} © mobilonline | {t("common:powered by eshop system")}{" "}
            <a href="https://www.grandus.sk" rel="nofollow sponsored">
              Grandus
            </a>{" "}
            {t("common:from the company")}{" "}
            <a href="https://www.forbestclients.com" rel="nofollow sponsored">
              For Best Clients, s.r.o.
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
