const LocationIcon = ({ width = 32, height = 32 }) => (
  <svg
    height={width}
    width={height}
    viewBox={`0 0 32 32`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>location</title>
    <g>
      <path d="M14.558 3.251c-2.064.374-3.463 1.124-4.995 2.675-1.842 1.865-2.584 3.637-2.595 6.199-.006 1.53.226 2.775.92 4.95 1.694 5.297 4.788 10.39 6.949 11.437 1.254.608 2.346.303 3.752-1.047 2.122-2.038 4.789-7.3 5.917-11.676 1.133-4.392.513-7.292-2.12-9.925-1.235-1.235-2.32-1.898-3.822-2.335-1.127-.328-3.01-.458-4.006-.278m3.26 1.814c1.312.335 2.277.896 3.306 1.923 1.526 1.522 2.247 3.212 2.247 5.262 0 3.722-3.434 11.704-6.071 14.115-.735.672-1.25.861-1.699.625-1.259-.663-2.987-3.117-4.483-6.365-2.07-4.493-2.84-7.832-2.317-10.048.611-2.591 2.78-4.833 5.324-5.504.913-.241 2.764-.245 3.692-.008M14.813 8.16c-1.15.47-2.033 1.331-2.365 2.307-.611 1.793.13 3.663 1.821 4.6.596.33.786.37 1.732.369.907-.002 1.155-.05 1.699-.336.773-.406 1.36-1.052 1.77-1.947.258-.562.3-.815.26-1.568-.078-1.45-.796-2.553-2.104-3.23-.658-.34-2.196-.447-2.813-.195m1.91 1.66c.707.294 1.117.758 1.283 1.452.467 1.945-1.825 3.33-3.365 2.034-1.176-.99-.85-2.85.61-3.482.563-.243.899-.245 1.473-.005" />
    </g>
  </svg>
);

export default LocationIcon;
