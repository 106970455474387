import Link from "next/link";
import InfolineStatus from "components/header/infolineStatus";
import Image from "next/image";

import styles from "./HeaderCart.module.scss";

const Header = () => {
  return (
    <>
      <style jsx global>
        {`
          /* LOADER */
          body {
            padding-top: 111px !important;
          }
        `}
      </style>
      <header id={"header"}>
        <div className={styles.container}>
          <div>
            <Link href="/" as={`/`}>
              <a className={"logo-anchor"}>
                <Image
                  src={"/foon_logo_white_small.gif"}
                  width={180}
                  height={35}
                  alt={"Foon logo"}
                />
              </a>
            </Link>
          </div>

          <div className={styles.infoline}>
            <InfolineStatus revalidateOnFocus={false} />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
