import Link from "next/link";
import { useRouter } from "next/router";
import { getUrlObject } from "utils/routes";

const LangLink = ({ children, ...rest }) => {
  const router = useRouter();
  const routeObj = { ...rest };
  routeObj.href = routeObj?.href?.pathname
    ? getUrlObject(
        routeObj?.href?.pathname,
        routeObj?.href?.query,
        router?.locale
      )
    : getUrlObject(routeObj?.href, {}, router?.locale);

  if (routeObj?.as) {
    delete routeObj.as;
  }

  return <Link {...routeObj}>{children}</Link>;
};

export default LangLink;
