const CartIcon = ({ width = 32, height = 32 }) => (
  <svg
    height={width}
    width={height}
    viewBox={`0 0 32 32`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>bag</title>
    <g>
      <path d="M30.855 6.56c.008-.21 0-.362 0-.43v-.109c-.009-.05-.05-.11-.118-.185a1.143 1.143 0 0 0-.497-.312l-.008-.008c-.135-.05-.295-.05-.472-.042h-6.526c-.396.025-.86.26-.96.75-.026.109-.169.707-.287 1.17-.32 1.238-.37 1.28-.471 1.372-.202.16-.421.135-.556.127-.025 0-.05-.009-.076-.009-3.14.009-7.975.009-11.873.009H1.743c-.303.025-.539.11-.716.252l-.008.017c-.244.202-.337.446-.27.766l.531 2.156c.657 2.62 1.76 7.023 1.937 7.705.076.329.278.54.623.674l.025.009c.118.042.261.075.447.067h.185c9.263 0 15.267-.008 15.722-.034.303-.076.53-.236.674-.463.151-.463 1.423-5.533 2.35-9.255.277-1.12.538-2.147.749-2.99.008-.05.025-.092.025-.134.084-.446.345-.488.665-.471h5.204c.245-.009.447-.06.573-.16h.017c.236-.16.345-.295.379-.472zm-9.87 4.682c-.008.025-.008.05-.008.076l-1.516 6.038c-.076.295-.152.623-.253 1.036-.084.336-.277.42-.539.43H5.6c-.396 0-.547-.119-.64-.515-.295-1.187-.598-2.408-.893-3.579l-.185-.715c-.21-.85-.42-1.718-.631-2.535a.661.661 0 0 1-.043-.185l-.008-.026a.526.526 0 0 1 .11-.446.624.624 0 0 1 .395-.202c1.777-.025 15.293-.034 16.783-.008h.017a.549.549 0 0 1 .413.218.474.474 0 0 1 .076.413zM3.705 24.22c.009-.547.152-.935.447-1.246a1.786 1.786 0 0 1 2.635-.085 1.792 1.792 0 0 1-.033 2.527 1.794 1.794 0 0 1-2.51-.017c-.345-.337-.539-.766-.539-1.18zm17.002-.05c0 .42-.151.808-.446 1.136a1.77 1.77 0 0 1-2.695.009 1.804 1.804 0 0 1 0-2.367 1.77 1.77 0 0 1 2.695.026c.278.32.43.707.446 1.195z" />
    </g>
  </svg>
);

export default CartIcon;
