import useSWR from "swr";
import { get, indexOf, remove, uniq, map } from "lodash";
import Link from "next/link";
import { getCategoryLinkAttributes } from "grandus-lib/hooks/useFilter";
import { useState } from "react";

import styles from "./Menu.module.scss";

import { ArrowDownIcon } from "components/_others/icons";
import Image from "grandus-lib/components-atomic/image/Image";
import upperFirst from "lodash/upperFirst";
import { useTranslation } from 'next-i18next';

const Menu = ({ isOpen = false, updateOpenedMenu }) => {
  const { data, error } = useSWR("/api/lib/v1/categories", (url) =>
    fetch(url).then((r) => r.json())
  );

  const { t } = useTranslation();
  const [openedCategories, setOpenedCategory] = useState([]);
  const onClickToggleOpenCategory = (categoryId) => {
    if (indexOf(openedCategories, categoryId) >= 0) {
      setOpenedCategory(remove(openedCategories, (n) => n != categoryId));
    } else {
      setOpenedCategory(uniq([...openedCategories, categoryId]));
    }
  };
  return (
    <div className={styles["mo-megamenu"]}>
      <nav>
        {!data ? (
          <ul className={styles.main} style={{ height: "50px" }}>
            <li>&nbsp;</li>
          </ul>
        ) : (
          <ul className={`${styles.main} ${isOpen ? styles.open : ""}`}>
            {map(data, (item, index) => {
              const submenuItemsCount = get(item, "children", []).length;
              return (
                <li key={`menu-item-${get(item, "id")}-${index}`}>
                  <>
                    <Link
                      {...getCategoryLinkAttributes(
                        get(item, "urlName"),
                        "",
                        {},
                        { absoluteHref: get(item, "externalUrl") }
                      )}
                      scroll={true}
                    >
                      <a
                        className={
                          submenuItemsCount ? styles["has-submenu"] : ""
                        }
                      >
                        {get(item, "name")}
                      </a>
                    </Link>
                    <Link
                      {...getCategoryLinkAttributes(
                        get(item, "urlName"),
                        "",
                        {},
                        { absoluteHref: get(item, "externalUrl") }
                      )}
                      scroll={true}
                    >
                      <a
                        className={`${
                          submenuItemsCount ? styles["has-submenu"] : ""
                        } ${styles.mobile} ${
                          indexOf(openedCategories, get(item, "id")) >= 0
                            ? styles.open
                            : ""
                        }`}
                        onClick={(e) => {
                          if (submenuItemsCount) {
                            e.preventDefault();
                            onClickToggleOpenCategory(get(item, "id"));
                            return false;
                          } else {
                            updateOpenedMenu(false);
                          }
                        }}
                      >
                        {get(item, "name")}
                        {submenuItemsCount ? <ArrowDownIcon /> : null}
                      </a>
                    </Link>
                  </>

                  {submenuItemsCount ? (
                    <div
                      className={`${styles["mo-megamenu-item"]} ${
                        indexOf(openedCategories, get(item, "id")) >= 0
                          ? styles.open
                          : ""
                      }`}
                    >
                      {map(get(item, "children", []), (subItem, index) => {
                        const hasSubSubmenu = get(
                          subItem,
                          "children",
                          []
                        ).length;
                        return (
                          <div
                            key={`menu-sub-item-${get(item, "id")}-${get(
                              subItem,
                              "id"
                            )}-${index}`}
                            className={`${styles["mo-column"]} ${
                              styles[
                                `mo-col-${
                                  submenuItemsCount > 5 ? 5 : submenuItemsCount
                                }`
                              ]
                            }`}
                          >
                            {index == 0 ? (
                              <Link
                                {...getCategoryLinkAttributes(
                                  get(item, "urlName")
                                )}
                                scroll={true}
                              >
                                <a
                                  className={`${styles["mo-megamenu-title"]} ${styles.mobile}`}
                                  onClick={() => {
                                    updateOpenedMenu(false);
                                  }}
                                >
                                  {upperFirst(t("common:all from category"))}{" "}{get(item, "name")}
                                </a>
                              </Link>
                            ) : (
                              ""
                            )}
                            <Link
                              {...getCategoryLinkAttributes(
                                get(subItem, "urlName"),
                                "",
                                {},
                                { absoluteHref: get(subItem, "externalUrl") }
                              )}
                              scroll={true}
                            >
                              <a
                                className={`${styles["mo-megamenu-title"]} ${
                                  hasSubSubmenu ? styles["has-submenu"] : ""
                                }`}
                              >
                                <Image
                                  photo={get(subItem, "alternativePhoto", {})}
                                  size={"20x28"}
                                  type={"png"}
                                />
                                {get(subItem, "name")}
                              </a>
                            </Link>
                            <Link
                              {...getCategoryLinkAttributes(
                                get(subItem, "urlName"),
                                "",
                                {},
                                { absoluteHref: get(subItem, "externalUrl") }
                              )}
                              scroll={true}
                            >
                              <a
                                className={`${
                                  hasSubSubmenu ? styles["has-submenu"] : ""
                                } ${styles.mobile} ${
                                  indexOf(
                                    openedCategories,
                                    get(subItem, "id")
                                  ) >= 0
                                    ? styles.open
                                    : ""
                                }`}
                                onClick={(e) => {
                                  if (hasSubSubmenu) {
                                    e.preventDefault();
                                    onClickToggleOpenCategory(
                                      get(subItem, "id")
                                    );
                                    return false;
                                  } else {
                                    updateOpenedMenu(false);
                                  }
                                }}
                              >
                                {get(subItem, "name")}
                                {hasSubSubmenu ? <ArrowDownIcon /> : null}
                              </a>
                            </Link>
                            {hasSubSubmenu ? (
                              <div
                                className={`${styles["mo-megamenu-wrap"]} ${
                                  indexOf(
                                    openedCategories,
                                    get(subItem, "id")
                                  ) >= 0
                                    ? styles.open
                                    : ""
                                }`}
                              >
                                <ul>
                                  <li>
                                    <Link
                                      {...getCategoryLinkAttributes(
                                        get(subItem, "urlName")
                                      )}
                                      scroll={true}
                                    >
                                      <a
                                        className={`${styles.mobile}`}
                                        onClick={() => {
                                          updateOpenedMenu(false);
                                        }}
                                      >
                                        {upperFirst(t("common:all from category"))}{" "}
                                        {get(subItem, "name")}
                                      </a>
                                    </Link>
                                  </li>
                                  {map(
                                    subItem?.children,
                                    (subSubItem, index) => {
                                      if (
                                        index == 6 &&
                                        hasSubSubmenu !== index
                                      ) {
                                        return (
                                          <li
                                            key={`menu-sub-item-${get(
                                              item,
                                              "id"
                                            )}-${get(subItem, "id")}-${get(
                                              subSubItem,
                                              "id",
                                              "000"
                                            )}-${index}`}
                                          >
                                            <Link
                                              {...getCategoryLinkAttributes(
                                                get(subItem, "urlName"),
                                                "",
                                                {},
                                                {
                                                  absoluteHref: get(
                                                    subItem,
                                                    "externalUrl"
                                                  ),
                                                }
                                              )}
                                              scroll={true}
                                            >
                                              <a>
                                                <u>{upperFirst(t("common:show more"))}</u>
                                              </a>
                                            </Link>
                                          </li>
                                        );
                                      }
                                      if (index > 6) {
                                        return "";
                                      }
                                      return (
                                        <li
                                          key={`menu-sub-item-${get(
                                            item,
                                            "id"
                                          )}-${get(subItem, "id")}-${get(
                                            subSubItem,
                                            "id"
                                          )}-${index}`}
                                        >
                                          <Link
                                            {...getCategoryLinkAttributes(
                                              get(subSubItem, "urlName"),
                                              "",
                                              {},
                                              {
                                                absoluteHref: get(
                                                  subSubItem,
                                                  "externalUrl"
                                                ),
                                              }
                                            )}
                                            scroll={true}
                                          >
                                            <a
                                              className={styles.always}
                                              onClick={() => {
                                                updateOpenedMenu(false);
                                              }}
                                            >
                                              {get(subSubItem, "name")}
                                            </a>
                                          </Link>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </nav>
    </div>
  );
};

export default Menu;
