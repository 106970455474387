import useWebInstance from "grandus-lib/hooks/useWebInstance";
import { find, get, first } from "lodash";
import useSWR from "swr";

import InfolineDefault from "components/header/infolineStatus/InfolineDefault";
import InfolineSmall from "components/header/infolineStatus/InfolineSmall";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const getAvailabilityByTime = () => {
  const now = dayjs();
  const todayDate = now.format("YYYY-MM-DD");

  return (
    now.isSameOrAfter(`${todayDate} 08:00`) &&
    now.isSameOrBefore(`${todayDate} 15:00`) &&
    !now.isBetween(`${todayDate} 11:00`, `${todayDate} 11:30`, null, "[)") &&
    !(now.format("d") == 6 || now.format("d") == 0) // not saturday or sunday
  );
};

const InfolineStatus = ({ type = null, revalidateOnFocus = true }) => {
  const { webInstance } = useWebInstance();
  const { data: staticBlocks, isValidating } = useSWR(
    `/api/lib/v1/blocks?cache=0`,
    (url) => fetch(url).then((r) => r.json()),
    {
      revalidateOnReconnect: false,
      revalidateOnFocus: revalidateOnFocus,
      shouldRetryOnError: false,
    }
  );
  const infolineStatus = find(staticBlocks, ["hash", "infoline_status"]);
  let available = parseInt(
    first(get(infolineStatus, "content", "").match(/\d/))
  );

  if (available === 2) {
    available = getAvailabilityByTime();
  }

  if (isValidating) {
    return "";
  }

  let InfolineComponent = InfolineDefault;
  if (type === "small") {
    InfolineComponent = InfolineSmall;
  }

  return (
    <InfolineComponent
      available={available}
      phone={get(webInstance, "globalSettings.phone", "")}
      email={get(webInstance, "adminEmail", "")}
    />
  );
};

export default InfolineStatus;
